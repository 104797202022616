// Fonts

@import url("//hello.myfonts.net/count/3db42d");

@font-face {
    font-family: "Calibri";
    font-style:   normal;
    font-stretch: normal;
    font-display: swap; /* Read next point */ 
    unicode-range: U+000-5FF; /* Download only latin glyphs */ 
    font-weight: 400;
    src: local('Calibri'), 
    url('../../webfonts/Calibri/font.woff2') format('woff2'), 
    url('../../webfonts/Calibri/font.woff') format('woff');
}

@font-face {
    font-family: "Calibri";
    font-style:   normal;
    font-stretch: normal;
    font-display: swap; /* Read next point */ 
    unicode-range: U+000-5FF; /* Download only latin glyphs */ 
    font-weight: 700;
    src: local('CalibriBold'),
    url('../../webfonts/CalibriBold/font.woff2') format('woff2'), 
    url('../../webfonts/CalibriBold/font.woff') format('woff');
}
