// Form
input:focus {
    outline: none;
}

.form-input,
.form-select {
    -webkit-border-radius: 0;
    border-radius: 0;
    &:focus {
        outline: none;
    }
}

.form-group {
    @apply mb-4;
}

.form-label {
    @apply block w-full mb-1 font-bold;
}

.form-input,
.form-select {
    @apply block w-full px-4 py-2;
    @screen lg {
        @apply px-7 py-3;
    }
}

.form-select.freeform-select {
	@apply pl-4 pr-8;
	@screen lg {
		@apply px-5;
		background-position: right 20px center;
	}
}

.form-link {
	@apply block px-4 py-2 cursor-pointer;
	@screen lg {
		@apply px-7 py-3;
	}
}
.form-select {
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-image: url('data:image/svg+xml; charset=utf-8, <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.9375 8.09375H5.89844C5.97656 8.17188 6.09375 8.21094 6.25 8.21094C6.36719 8.21094 6.48438 8.17188 6.60156 8.09375L12.3828 2.35156C12.4609 2.27344 12.5 2.15625 12.5 2.03906C12.5 1.92188 12.4609 1.80469 12.3828 1.6875L11.6016 0.90625C11.4844 0.828125 11.3672 0.789062 11.25 0.789062C11.0938 0.789062 11.0156 0.828125 10.9375 0.90625L6.25 5.55469L1.5625 0.90625C1.48438 0.828125 1.36719 0.789062 1.25 0.789062C1.09375 0.789062 0.976562 0.828125 0.898438 0.90625L0.117188 1.6875C0.0390625 1.80469 0 1.92188 0 2.03906C0 2.15625 0.0390625 2.27344 0.15625 2.35156L5.9375 8.09375Z" fill="%23444444"/></svg>');
    @apply bg-white;
    @screen lg {
        background-position: right 2px center;
    }
}

.form-checkbox {
    height: initial;
    width: initial;
    @apply hidden cursor-pointer m-0 p-0;
}

.form-checkbox + label:before {
    content: '';
    @apply inline-block p-2 mr-4 bg-white cursor-pointer;
}

.form-checkbox:checked + label:before {
    @apply bg-navy-400;
}

.form-checkbox:checked + label:after {
    content: '';
    display: block;
    // top: 3px;
    left: 5px;
    margin-top: -2px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    @apply absolute border-white;
}

// Fake select dropwdown with radio inputs

.select-dropdown {
    @apply block relative pt-2 text-navy-700;
    @screen md {
        @apply inline-block pt-0 ml-6;
    }
    @screen md {
        @apply ml-8;
    }
}

.select-dropdown__title {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.34375 7.48438H5.30859C5.37891 7.55469 5.48438 7.58984 5.625 7.58984C5.73047 7.58984 5.83594 7.55469 5.94141 7.48438L11.1445 2.31641C11.2148 2.24609 11.25 2.14062 11.25 2.03516C11.25 1.92969 11.2148 1.82422 11.1445 1.71875L10.4414 1.01562C10.3359 0.945312 10.2305 0.910156 10.125 0.910156C9.98438 0.910156 9.91406 0.945312 9.84375 1.01562L5.625 5.19922L1.40625 1.01562C1.33594 0.945312 1.23047 0.910156 1.125 0.910156C0.984375 0.910156 0.878906 0.945312 0.808594 1.01562L0.105469 1.71875C0.0351562 1.82422 0 1.92969 0 2.03516C0 2.14062 0.0351562 2.24609 0.140625 2.31641L5.34375 7.48438Z" fill="%23133D59"/></svg>');
    background-position: right center;
    background-repeat: no-repeat;
    @apply pr-6 font-bold;
}

.select-dropdown__title:hover {
    @apply cursor-pointer;
}

.select-dropdown__options {
    max-height: 0;
    z-index: 999;
    transition-property: opacity, padding, max-height;
    @apply overflow-hidden opacity-0 bg-white duration-300 ease-in-out;
    @screen md {
        width: 260px;
        @apply absolute left-0;
    }
}

.select-dropdown:hover .select-dropdown__options {
    max-height: 1000px;
    @apply opacity-100 py-3;
}

.select-dropdown__options label {
    @apply block w-full px-6 py-1 transition duration-300 ease-in-out;
}

.select-dropdown__options label:hover {
    @apply cursor-pointer bg-navy-200;
}

.select-dropdown__options input[type="radio"]:checked + label {
    @apply bg-white bg-navy-200;
}

.filters-btn {
    @apply flex flex-wrap justify-center items-center;
    &:after {
        content: url('data:image/svg+xml; charset=utf-8, <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9375 6.125C13.0781 6.125 13.2188 6.19531 13.3242 6.30078C13.4297 6.40625 13.5 6.54688 13.5 6.6875V7.8125C13.5 7.98828 13.4297 8.12891 13.3242 8.23438C13.2188 8.33984 13.0781 8.375 12.9375 8.375H7.875V13.4375C7.875 13.6133 7.80469 13.7539 7.69922 13.8594C7.59375 13.9648 7.45312 14 7.3125 14H6.1875C6.01172 14 5.87109 13.9648 5.76562 13.8594C5.66016 13.7539 5.625 13.6133 5.625 13.4375V8.375H0.5625C0.386719 8.375 0.246094 8.33984 0.140625 8.23438C0.0351562 8.12891 0 7.98828 0 7.8125V6.6875C0 6.54688 0.0351562 6.40625 0.140625 6.30078C0.246094 6.19531 0.386719 6.125 0.5625 6.125H5.625V1.0625C5.625 0.921875 5.66016 0.78125 5.76562 0.675781C5.87109 0.570312 6.01172 0.5 6.1875 0.5H7.3125C7.45312 0.5 7.59375 0.570312 7.69922 0.675781C7.80469 0.78125 7.875 0.921875 7.875 1.0625V6.125H12.9375Z" fill="%23133D59"/></svg>');
        @apply ml-2;
    }
}

.filters-btn.is-active {
    &:after {
        content: url('data:image/svg+xml; charset=utf-8, <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9375 0.125H0.5625C0.246094 0.125 0 0.40625 0 0.6875V1.8125C0 2.12891 0.246094 2.375 0.5625 2.375H12.9375C13.2188 2.375 13.5 2.12891 13.5 1.8125V0.6875C13.5 0.40625 13.2188 0.125 12.9375 0.125Z" fill="%23133D59"/></svg>');
    }
}

.filters {
    max-height: 0;
    transition-property: opacity, padding, max-height;
    @apply overflow-hidden opacity-0 bg-white duration-300 ease-in-out;
    @screen md {
        max-height: 1000px;
        @apply opacity-100 overflow-visible;
    }

    .form-select {
        @screen md {
            @apply font-bold text-navy-700 cursor-pointer;

            option {
                @apply font-normal;
            }
        }
		@screen lg {
			min-width: 240px;
		}
    }
}

.filters.is-active {
    max-height: 1000px;
    @apply opacity-100;
}


.ff-form-success,
.ff-form-errors {
    @apply w-full;

    p {
        @apply pl-5 font-bold mb-6;

        @screen md { 
            @apply pl-8;
        }
    }
} 

.ff-errors, .ff-form-errors, .freeform-form-has-errors, .errors li {

        @apply text-error;

}


form input.button {
    &:hover {
        @apply bg-navy-700;
    }
}

// .search__input:focus {
//     @apply border-2 border-navy-500;
// }
