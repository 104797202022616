// Sections
// Spacing and general styles for sections


// Gutters - default
$gutters: (
    md: 64px,
    lg: 88px
);

// Gutters - slightly less than default
$gutters-sm: (
    md: 30px,
    lg: 60px
);

@responsive {
    .padding {
        padding-top: 40px;
    	padding-bottom: 40px;
    	@each $breakpoint, $value in $gutters {
    		@screen #{$breakpoint} {
    			padding-top: $value;
    			padding-bottom: $value;
    		}
    	}
    }
}

@responsive {
    .margin {
    	margin-top: 40px;
    	margin-bottom: 64px;
    	@each $breakpoint, $value in $gutters {
    		@screen #{$breakpoint} {
    			margin-top: $value;
    			margin-bottom: $value;
    		}
    	}
    }
}

.margin-sm {
	margin-top: 30px;
	margin-bottom: 30px;
	@each $breakpoint, $value in $gutters-sm {
		@screen #{$breakpoint} {
			margin-top: $value;
			margin-bottom: $value;
		}
	}
}

.padding-sm {
	padding-top: 30px;
	padding-bottom: 30px;
	@each $breakpoint, $value in $gutters-sm {
		@screen #{$breakpoint} {
			padding-top: $value;
			padding-bottom: $value;
		}
	}
}

.accordion-block + .accordion-block { 
	@apply -mt-6; 

	@screen md { 
		@apply -mt-8;
	}

	@screen lg { 
		@apply -mt-12;
	}
}

.event-img-container {
	@apply max-w-full;

	@screen md {
		max-width: 38%;
	}
}

.event-block {
	@apply relative;
	&::before {
		content: "";
		@apply absolute z-10 top-0 right-0 w-full bg-white;
		height: 50%;
		@screen md {
			height: 40%;
		}
	}
}