// Typography

body, input, select, textarea {
    font-size: 16px;
	line-height: 1.4;
    @apply font-sans font-normal text-grey-800;
	@screen md {
	}
	@screen lg {
        font-size: 18px;
	}
}

strong, b {
    font-weight: 700;
}

p strong {
    font-weight: 700 !important;
}

.h1,
.typography h1 {
    font-size: 22px;
    line-height: 1.1;
    @apply font-headings font-bold break-words;
    @screen lg {
        font-size: 44px;
    }
}

.h2,
.typography h2 {
    font-size: 22px;
    line-height: 1.24;
    @apply font-headings font-bold;
    @screen lg {
        font-size: 40px;
        line-height: 1.2;
    }
}

.h3,
.typography h3 {
    font-size: 18px;
    line-height: 1.36;
    @apply font-headings font-bold;
    @screen lg {
        font-size: 26px;
        line-height: 1.24;
    }
}

.h4,
.typography h4 {
    font-size: 22px;
    line-height: 1.24;
    @apply font-bold;
    @screen xl {
        font-size: 24px;
    }
}

.h5,
.typography h5 {
    font-size: 20px;
    @apply font-bold;
    @screen lg {
        font-size: 22px;
    }
}

.h6,
.typography h6 {
    font-size: 18px;
    @apply font-bold;
    @screen lg {
        font-size: 20px;
    }
}

blockquote:not(.small-quote),
.quote {
    font-size: 22px;
    line-height: 1.24;
    @apply font-headings font-bold text-navy-700;
    @screen lg {
        font-size: 28px;
    }
    @screen xl {
        font-size: 36px;
    }
}

blockquote:not(.small-quote):before,
.quote:before {
    content: url('data:image/svg+xml; utf8, <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.6926 0H27.36L21.6144 12.1388H27.36V24H15.6734V12.1388L21.6926 0ZM5.98011 0H11.6866L5.90194 12.1388H11.6866V24H0V12.1388L5.98011 0Z" fill="%23133D59"/></svg>');
    @apply block mb-2;
    @screen xl {
        content: url('data:image/svg+xml; utf8, <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45.1929 0.689453H57L45.03 25.5004H57V49.7437H32.6529V25.5004L45.1929 0.689453ZM12.4586 0.689453H24.3471L12.2957 25.5004H24.3471V49.7437H0V25.5004L12.4586 0.689453Z" fill="%23133D59"/></svg>');
        @apply mb-4;
    }
}

blockquote:not(.small-quote).text-white:before,
.quote.text-white:before {
    content: url('data:image/svg+xml; utf8, <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.6926 0H27.36L21.6144 12.1388H27.36V24H15.6734V12.1388L21.6926 0ZM5.98011 0H11.6866L5.90194 12.1388H11.6866V24H0V12.1388L5.98011 0Z" fill="white"/></svg>');
    @screen xl {
        content: url('data:image/svg+xml; utf8, <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45.1929 0.689453H57L45.03 25.5004H57V49.7437H32.6529V25.5004L45.1929 0.689453ZM12.4586 0.689453H24.3471L12.2957 25.5004H24.3471V49.7437H0V25.5004L12.4586 0.689453Z" fill="white"/></svg>');
    }
}


.tag {
    font-size: 12px;
    @apply inline-block font-headings font-bold uppercase text-navy-400;
    @screen lg {
        font-size: 14px;
    }
}

.small-quote {
    @apply relative pl-8 ml-2 mb-6 italic text-navy-700 border-l-2 border-navy-300;
    @screen lg {
        @apply pl-10;
    }
    @screen xl {
        font-size: 20px;
        @apply pl-12 py-2 mb-8;
    }
}

.small-quote:after {
    content: '';
    width: 16px;
    height: 16px;
    top: 50%;
    left: -9px;
    transform: translateY(-50%);
    @apply absolute block bg-white border-2 border-navy-300;
}


// Redactor (WYSIWYG) content styles
.typography {
    h1 {
        @apply mb-3;
    }
    h2 {
        @apply text-navy-700 mb-3;
    }
    h3 {
        @apply text-navy-700 mb-3;
    }
    h4, h5 {
        @apply text-navy-700 mb-2;
    }
    p, ul, ol {
        @apply mb-7;
    	@screen lg {
    		@apply mb-7;
    	}
        &:last-child {
            @apply mb-0;
        }
    }
	ul li {
        @apply relative pl-5 w-full inline-block;
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            top: 9px;
            @apply block absolute left-0 bg-grey-800 rounded-full;

            @screen lg {
                top: 11px;
            }
        }

        &:after { 
            content: '';
            width: 5px;
            height: 5px;
            @apply block;
        }
	}
    &.text-white {
        ul li {
            &:before {
                @apply bg-white;
            }
        }
    }
	ol li {
        @apply list-decimal list-outside pl-4 ml-6;
	}
    table {
        @apply w-full table-fixed border-b-4 border-navy-300 text-base;
        th {
            @apply px-6 py-2 bg-navy-700 font-normal text-white text-left;
        }
        td {
            @apply px-6 py-2 text-left;

            &:first-child {
                @apply font-bold;
            }
        }
        tr:nth-child(odd) td {
            @apply bg-navy-100;
        }
        tr:nth-child(even) td {
            @apply bg-navy-200;
        }
        tr:first-child td { 
            @apply px-6 py-2 bg-navy-700 font-normal text-xl text-white text-left;
        }
    }
    img {
    }
    a:not(.button) {
        @apply text-navy-500 underline;
        &:hover {
            @apply no-underline;
        }
    }

    hr {
        margin-bottom: 24px !important;
    }
}

figure {
    @apply mb-6;

    figcaption {
        @apply mt-4 text-sm;
    }
}
