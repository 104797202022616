// Pagination

.pagination {
    @apply flex justify-center items-center;
    @screen md {
    }
    @screen lg {
    }
}

.pagination__link {
    font-size: 18px;
    @apply px-2 pb-1 font-bold text-navy-700 border-b-2 border-transparent cursor-pointer;
    @screen lg {
        font-size: 20px;
    }
}

.pagination__link:hover {
    @apply border-navy-700;
}

.pagination__link--active {
    @apply border-navy-700;
}

.left-arrow .pagination__prev,
.right-arrow .pagination__next {
    font-size: 0;
    height: 48px;
    width: 48px;
    @apply overflow-hidden flex justify-center items-center mx-4 bg-navy-500 text-navy-500 rounded-full;
    &:hover {
       @apply bg-navy-700 text-navy-700 cursor-pointer;
    }
    &--disabled {
      @apply opacity-50;
    }
}

.pagination__item {
    @apply inline-block mx-1;

    &.active {
        .pagination__link {
            @apply border-navy-700 cursor-auto;
        }
    }

    &.disabled {
        &.left-arrow .pagination__prev,
        &.right-arrow .pagination__next {
            @apply opacity-50 cursor-auto bg-navy-500;
        }
    }
}

.left-arrow .pagination__prev:before {
    content: url('data:image/svg+xml; utf8, <svg width="15" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.60352 14.8633L9.26758 14.2324C9.43359 14.0664 9.43359 13.8008 9.26758 13.668L4.12109 8.48828H15.4766C15.6758 8.48828 15.875 8.32227 15.875 8.08984V7.16016C15.875 6.96094 15.6758 6.76172 15.4766 6.76172H4.12109L9.26758 1.61523C9.43359 1.48242 9.43359 1.2168 9.26758 1.05078L8.60352 0.419922C8.4707 0.253906 8.20508 0.253906 8.03906 0.419922L1.09961 7.35938C0.933594 7.52539 0.933594 7.75781 1.09961 7.92383L8.03906 14.8633C8.20508 15.0293 8.4707 15.0293 8.60352 14.8633Z" fill="white"/></svg>');
}

.right-arrow .pagination__next:before {
    content: url('data:image/svg+xml; utf8, <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.23828 0.386719C7.30469 0.320312 7.4043 0.287109 7.53711 0.287109C7.63672 0.287109 7.73633 0.320312 7.80273 0.386719L14.7422 7.35938C14.8086 7.42578 14.875 7.52539 14.875 7.625C14.875 7.75781 14.8086 7.82422 14.7422 7.89062L7.80273 14.8633C7.73633 14.9297 7.63672 14.9629 7.53711 14.9629C7.4043 14.9629 7.30469 14.9297 7.23828 14.8633L6.57422 14.1992C6.50781 14.1328 6.47461 14.0664 6.47461 13.9336C6.47461 13.834 6.50781 13.7344 6.57422 13.6348L11.7207 8.48828H0.398438C0.265625 8.48828 0.166016 8.45508 0.0996094 8.38867C0.0332031 8.32227 0 8.22266 0 8.08984V7.16016C0 7.06055 0.0332031 6.96094 0.0996094 6.89453C0.166016 6.82812 0.265625 6.76172 0.398438 6.76172H11.7207L6.57422 1.61523C6.50781 1.54883 6.47461 1.44922 6.47461 1.31641C6.47461 1.2168 6.50781 1.11719 6.57422 1.05078L7.23828 0.386719Z" fill="white"/></svg>');
}
