// Layout
// Rows and columns for easier margin/spacing management

.container {
    max-width: 1920px;
    @apply mx-auto overflow-hidden;
}

@responsive {
    .row {
        max-width: 1284px;
        @apply flex flex-wrap mx-auto w-full;
    }
}

@responsive {
    .column {
        @apply w-full px-5;
        @screen md {
            @apply px-7;
        }
        @screen lg {
            @apply px-8;
        }
    }
}

.flush-right {
    @apply pl-5;
    @screen md {
        @apply pl-7;
    }
    @screen lg {
        @apply pl-8;
    }
    @media only screen and (min-width: 1284px) {    // Container width
        margin-left: calc((100% - 1284px) / 2);
    }
}
