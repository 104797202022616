// Base
// Includes styles that hardly change and don't require compiling as often

// Tailwind imports
@tailwind base;
@tailwind components;

// Imports
// Include variables, base styles, grids, typography, components and other partials
/* purgecss start ignore */
@import "partials/fonts";
@import "partials/forms";
@import "partials/home-banner";
@import "partials/layout";
@import "partials/pagination";
@import "partials/swiper";
@import "partials/typography";
@import "sections/sections";
/* purgecss end ignore */


// Base styles
html, body {
    scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a, a svg, .button, .link {
    @apply transition duration-300 ease-in-out;
}

svg {
    transition-property: fill;
}

.button {
    font-size: 12px;
    @apply relative overflow-hidden inline-block px-7 py-4 bg-navy-500 font-headings font-bold uppercase text-white z-0;
    @screen lg {
        @apply px-8 py-4;
    }
    @screen xl {
        @apply px-9 py-5;
    }

    svg {
        @apply ml-3 fill-white;

    }
}

.button:after {
    content: '';
    height: 100%;
    width: 130%;
    transform: translateX(-115%) skewX(-30deg);
    z-index: -10;
    @apply absolute top-0 left-0 bg-navy-600 transition-transform duration-500 ease-in-out;
}

.button:hover:after {
    transform: translateX(-15%) skewX(-30deg);
}

.button:hover {
    @apply cursor-pointer;
}

.link {
    font-size: 12px;
    @apply inline-block font-headings font-bold uppercase text-navy-500;
    text-decoration: none !important;
}

.link--white {
    @apply text-white;
}

.link:after {
    content: '';
    height: 2px;
    margin-top: 2px;
    transition-property: width;
    @apply block bg-navy-500 w-full duration-300 ease-in-out;
}

.link:hover:after {
    @apply w-0;
}

.link--white:after {
    @apply bg-white;
}

.button + .link {
    @apply ml-6;
}

.line {
    transition-property: opacity, transform;
    @apply block relative opacity-0 bg-navy-300 duration-1000 ease-in-out;
}

[data-scroll="in"] .line {
    @apply opacity-100;
}

.line--left,
.line--right {
    transform: translateY(-56px);
    height: 56px;
    width: 2px;
    @screen lg {
        transform: translateY(0);
        height: 2px;
        width: 235px;
    }
    @screen xl {
        width: 315px;
    }
}

.line--left {
    @screen lg {
        transform: translateX(-235px);
    }
    @screen xl {
        transform: translateX(-315px);
    }
}

.line--right {
    @screen lg {
        transform: translateX(235px);
    }
    @screen xl {
        transform: translateX(315px);
    }
}

[data-scroll="in"] .line--left,
[data-scroll="in"] .line--right {
    transform: translateY(0);
    @screen lg {
        transform: translateX(0);
    }
}

.line--down {
    height: 56px;
    transform: translateY(-56px);
    width: 2px;
    @apply mb-4;
    @screen lg {
        height: 125px;
        transform: translateY(-125px);
    }
}

.line--up {
    z-index: -1;
    height: 56px;
    transform: translateY(56px);
    width: 2px;
    @apply mt-4;
    @screen lg {
        height: 125px;
        transform: translateY(125px);
    }
}

.line--up:after {
    top: -16px;
    bottom: auto;
}

[data-scroll="in"] .line--down,
[data-scroll="in"] .line--up {
    transform: translateY(0);
}

.line:after {
    content: '';
    width: 16px;
    height: 16px;
    bottom: -16px;
    left: -7px;
    @apply absolute block border-2 border-navy-300;

}

.line--left:after {
    @screen lg {
        left: auto;
        bottom: auto;
        top: -7px;
        right: -16px;
    }
}

.line--right:after {
    @screen lg {
        top: -7px;
        left: -16px;
    }
}

.responsive-embed {
	min-height: 200px;
	@screen lg {
		min-height: 500px;
	}
    @apply relative;
	lite-youtube {
		max-width: 100%;
	}
}

.responsive-embed iframe {
    @apply absolute top-0 left-0 w-full h-full;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @apply fixed invisible overflow-hidden opacity-0 h-0 w-0 bg-white;


}
.lang.modal.active {
	@apply px-4 py-0;

	@screen lg {
		width: 800px;
	}

	@screen xl {
		width: 900px;
	}

}

.modal.active {
    width: 94%;
    @apply visible opacity-100 h-auto px-6 pt-10 pb-4 z-50;
    @screen md {
        width: 650px;
    }

    &.video-overlay {
        @apply max-w-5xl;
        width: calc(100% - 32px);
    }
}

.overlay {
    @apply overflow-hidden fixed top-0 bottom-0 left-0 right-0 opacity-0 h-0 bg-black bg-opacity-40 transition-opacity duration-500 ease-in-out z-40;
}

.overlay.active {
    @apply opacity-100 h-auto;
}

.bg-gradient {
    background-color: transparent;
    background-size: 1900px;
    background-position: 25% 25%;
    @apply bg-no-repeat bg-fixed;
    @screen lg {
        background-position: right -700px center;
    }
}

.bg-gradient-cream-100 {
    background-image: url('/img/bg-gradient-cream-100.svg');
}

.bg-gradient-navy-300 {
    background-image: url('/img/bg-gradient-navy-300-2.svg');
}

.bg-gradient-navy-500 {
    background-image: url('/img/bg-gradient-navy-500.svg');
}

.bg-gradient-navy-800 {
    background-image: url('/img/bg-gradient-navy-800.svg');
}

.bg-white + .bg-white {
    @apply pt-0;
}

[v-cloak] {
	display: none !important;
}

.bg-navy-600 {
    background-color: #045B8E;
}

.bg-navy-500 {
    background-color: #127CBA;
}

.bg-navy-400 {
    background-color: #00A1E0;
}

.to-white-transparent {
    --tw-gradient-to: rgba(255,255,255,0);
}
