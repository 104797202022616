.home-banner {

}

.home-banner__title {
    font-size: 28px;
    line-height: 1.12;
    @apply font-headings font-bold text-navy-700;
    @screen lg {
        font-size: 40px;
    }
    @screen xl {
        font-size: 52px;
    }
}
